import React from "react"
import { useAttorneys } from "../hooks/use-attorneys"
import AttorneyLarge from "./attorney-large"
import AttorneySmall from "./attorney-small"

const AttorneysSection = ({ children, large }) => {
  const { attorneys } = useAttorneys()
  console.log(attorneys)
  if (large === true) {
    return (
      <section className="section">
        <div className="container has-text-centered py-4">
          {children}
          <div class="columns py-5 is-multiline">
            {attorneys.map(attorney => (
              <div className="column is-4" key={attorney.node.id}>
                <AttorneyLarge attorney={attorney.node} />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }

  return (
    <div>
      {attorneys.map(attorney => (
        <AttorneySmall
          className="is-one-half column"
          key={attorney.node.id}
          attorney={attorney.node}
        />
      ))}
    </div>
  )
}

export default AttorneysSection
