import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import SidebarContactForm from "../components/sidebar-contact-form";
import AttorneysSection from "../components/attorneys-section";

class AboutPag extends React.Component {
  render() {
    const page = get(this, "props.data.contentfulPage");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${page.title}`}
          keywords={`${page.keywords ? page.keywords.join() : ""}`}
          description={page.seoDescription}
        />

        <div className="columns p-4">
          <div className="column">
            <GatsbyImage
              className="mb-4 hero-border-1"
              placeholder="traced_svg"
              width={page.image.gatsbyImageData.width}
              height={page.image.gatsbyImageData.height}
              alt={page.image.title}
              image={getImage(page.image)}
            />
            <div className="content">{renderRichText(page.body)}</div>
            <div className="content">
              <h1>Our Attorneys</h1>
              <AttorneysSection />
            </div>
            <div className="content">{renderRichText(page.footer)}</div>
          </div>
          <div className="column is-one-third">
            <SidebarContactForm />
          </div>
        </div>
      </Layout>
    );
  }
}

export default AboutPag;

export const pageQuery = graphql`
  query AttorneyListQuery {
    contentfulPage(slug: { eq: "about" }) {
      slug
      title
      keywords
      seoDescription
      image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: TRACED_SVG
        )
      }
      body {
        raw
      }
      footer {
        raw
      }
    }
  }
`;
