import { useStaticQuery, graphql } from "gatsby"

export const useAttorneys = (local = "en-US") => {
  const { allContentfulAttorney } = useStaticQuery(
    graphql`
      query AttorneysData {
        allContentfulAttorney(
          sort: { fields: [texasBarNumber], order: ASC }
          filter: { node_locale: { eq: "en-US" } }
        ) {
          attorneys: edges {
            node {
              id
              fullName
              firstName
              title
              texasBarNumber
              slug
              startDate(formatString: "MMMM Do, YYYY")
              shortBody {
                raw
              }
              body {
                raw
              }
              photo {
                title
                gatsbyImageData(
                  layout: CONSTRAINED
                  resizingBehavior: SCALE
                  placeholder: TRACED_SVG
                  width: 350
                  formats: WEBP
                )
              }
              largePhoto: photo {
                title
                gatsbyImageData(
                  layout: CONSTRAINED
                  resizingBehavior: SCALE
                  placeholder: TRACED_SVG
                  width: 250
                  formats: WEBP
                )
              }
            }
          }
        }
      }
    `
  )
  return allContentfulAttorney
}
