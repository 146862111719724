import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const AttorneySmall = ({ attorney }) => (
  <article className="media">
    <figure className="media-left">
      <p className="image is-128x128">
        <GatsbyImage
          className="is-rounded"
          placeholder="traced_svg"
          width={attorney.photo.gatsbyImageData.width}
          height={attorney.photo.gatsbyImageData.height}
          alt={attorney.photo.title}
          image={getImage(attorney.photo)}
        />
      </p>
    </figure>

    <div className="media-content">
      <div className="content">
        <p>
          <strong>
            <Link to={`/lawyers/${attorney.slug}/`}>{attorney.fullName}</Link>
          </strong>
          <br />
          {renderRichText(attorney.shortBody)}
        </p>
      </div>
    </div>
  </article>
);

export default AttorneySmall;
