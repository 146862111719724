import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
const AttorneyLarge = ({ attorney }) => (
  <>
    <div class="level">
      <div class="level-item">
        <figure class="image">
          <Link to={`/lawyers/${attorney.slug}/`}>
            <GatsbyImage
              image={getImage(attorney.largePhoto)}
              alt={attorney.photo.title}
              height={attorney.largePhoto.gatsbyImageData.height}
              width={attorney.largePhoto.gatsbyImageData.width}
              className="is-rounded"
            />
          </Link>
        </figure>
      </div>
    </div>

    <h2 class="title is-5">
      <Link to={`/lawyers/${attorney.slug}/`}>{attorney.fullName}</Link>
    </h2>

    <p class="subtitle is-6">{attorney.title}</p>
    <p>{renderRichText(attorney.shortBody)}</p>
  </>
);

export default AttorneyLarge;
