import React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/*import {
  faEnvelope,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";*/

export default function SidebarContactForm({ children }) {
  return (
    <form
      name="sidebar-contact"
      method="POST"
      data-netlify="true"
      action="/success"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="sidebar-contact" />
      <div className="box p-4 has-background-dark">
        <h1 className="title has-text-link-light">Free case evaluation</h1>

        <div className="field">
          <label htmlFor="name" className="label has-text-link-light">
            Name
          </label>
          <div className="control">
            <input
              name="name"
              id="name"
              className="input"
              type="text"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="email" className="label has-text-link-light">
            Email
          </label>
          <div className="control">
            <input
              name="email"
              id="email"
              className="input"
              type="email"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="phone" className="label has-text-link-light">
            Phone
          </label>
          <div className="control">
            <input
              name="phone"
              id="phone"
              className="input"
              type="text"
              placeholder="Phone"
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="message" className="label has-text-link-light">
            Tell Us More
          </label>
          <div className="control">
            <textarea
              name="message"
              id="message"
              className="textarea"
              placeholder="Write about your case..."
            ></textarea>
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control">
            <p class="is-hidden">
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>
            <button className="button is-primary" type="submit">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
